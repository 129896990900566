import { type GetStaticPropsResult } from 'next'
import dynamic from 'next/dynamic'

import { loginPageModuleQuery } from '@data/sanity/queries/page'
import { type SanityLoginPage } from '@data/sanity/queries/types/page'
import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import { getNonNullValues } from '@lib/helpers'
import {
  type BasePageProps,
  getPagePropsFromContext,
  getPageSanityClient,
} from '@lib/page'
import { type CustomStaticPropsContext } from '@lib/routes'
import { defaultModuleQueryParameters, getLoginPage } from '@lib/sanity/page'
import { getVideoObjectSchemas } from '@lib/schema/video'

import Layout from '@components/layout'
import Modules from '@modules/modules'

const PreviewModules = dynamic(() => import('@modules/preview-modules'))

type LoginPageProps = BasePageProps & {
  page: SanityLoginPage
  site: SanitySiteFragment
}

const LoginPage = ({ draftMode, locale, page, site }: LoginPageProps) => {
  const videoObjectSchemas = getVideoObjectSchemas(page)
  const schemas = [...videoObjectSchemas]

  return (
    <Layout page={page} site={site} schemas={schemas} draftMode={draftMode}>
      {!draftMode && <Modules modules={page.modules} />}
      {draftMode && (
        <PreviewModules
          modules={page.modules}
          query={loginPageModuleQuery}
          params={{
            ...defaultModuleQueryParameters,
            locale,
          }}
        />
      )}
    </Layout>
  )
}

export async function getStaticProps(
  context: CustomStaticPropsContext
): Promise<GetStaticPropsResult<LoginPageProps>> {
  const sanityClient = getPageSanityClient(!!context.draftMode)
  const loginPage = await getLoginPage(sanityClient, context.locale)

  if (!loginPage.page) {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      ...getPagePropsFromContext(context),
      page: getNonNullValues(loginPage.page),
      site: getNonNullValues(loginPage.site),
    },
    revalidate: 60,
  }
}

export default LoginPage
